import { appsApi } from './apiHelpers';

export enum CrossmintVerificationStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  UNDER_REVIEW = 'under_review',
  FAILED = 'failed',
}

export interface CrossmintVerificationResults {
  verificationStatus: {
    seller: {
      status: CrossmintVerificationStatus;
    };
    collection: {
      status: CrossmintVerificationStatus;
    };
  };
}

export async function getCrossmintVerificationStatus(
  crossmintId: string
): Promise<CrossmintVerificationResults> {
  try {
    const crossmintStatusJson = await appsApi.checkCrossmint(crossmintId);
    return crossmintStatusJson;
  } catch {
    return {
      verificationStatus: {
        seller: {
          status: CrossmintVerificationStatus.FAILED,
        },
        collection: {
          status: CrossmintVerificationStatus.FAILED,
        },
      },
    };
  }
}
