import dayjs from 'dayjs/esm/index';
import calendar from 'dayjs/esm/plugin/calendar';
import relativeTime from 'dayjs/esm/plugin/relativeTime';
import { computed } from 'vue';
import { useClaimStore } from '@/store/claimStore';

const CalendarFormat = {
  sameDay: '[today] [at] h:mm A', // today at 2:30 PM
  nextDay: '[tomorrow] [at] h:mm A', // tomorrow at 2:30 PM
  nextWeek: 'MMM D YYYY [at] h:mm A', // Feb 5 2023, at 2:30 PM
  lastDay: '[yesterday] h:mm A', // yesterday at 2:30 PM
  lastWeek: '[last] dddd [at] h:mm A', // last Monday at 2:30 PM
  sameElse: 'MMM D YYYY [at] h:mm A', // Mar 1 2023 at 2:30 PM
};

dayjs.extend(calendar);
dayjs.extend(relativeTime);

export function useDates() {
  const store = useClaimStore();

  function getCalendarString(dayjsDate: dayjs.Dayjs | null) {
    const formatted = dayjsDate?.calendar(null, CalendarFormat) || '';
    // Do not show 'zero' date strings.  Hacky, but it works.
    return formatted.startsWith('Dec 31 1969') || formatted.startsWith('Jan 1 1970')
      ? ''
      : formatted;
  }

  const claimStartDate = computed(() => {
    if (store.startDate) {
      return dayjs(store.startDate);
    }
    return null;
  });

  const claimEndDate = computed(() => {
    if (store.endDate) {
      return dayjs(store.endDate);
    }
    return null;
  });

  return { CalendarFormat, claimStartDate, claimEndDate, dayjs, getCalendarString };
}
