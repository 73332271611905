import { appsApi } from '@/api/apiHelpers';

export interface IMerkleInfo {
  merkleProof: string[];
  value: number;
}
export interface ICollectorApiClient {
  getMerkleInfos(merkleTreeId: number, walletAddress: string): Promise<IMerkleInfo[]>;
}

class CollectorAPIClient implements ICollectorApiClient {
  async getMerkleInfos(merkleTreeId: number, walletAddress: string): Promise<IMerkleInfo[]> {
    return appsApi.getMerkleTree(merkleTreeId, walletAddress);
  }
}

export default new CollectorAPIClient();
