/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BigNumber } from '@ethersproject/bignumber';
import { formatBytes32String } from '@ethersproject/strings';
import { formatEther, formatUnits, parseEther, parseUnits } from '@ethersproject/units';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { setProviderEventsHandler } from '@manifoldxyz/dropsite-lib';
import { detectManifoldEthereumProvider } from '@manifoldxyz/frontend-provider-utils';
import { URLs } from '@manifoldxyz/js-ts-utils';
import { signatureApi } from '@/api/apiHelpers';
import { getERC20ToUSDRate, getEthToUsdRate } from '@/api/coinbase';
import collectors, { IMerkleInfo } from '@/api/collectors';
import { getTwitterHandle } from '@/api/creator';
import { CrossmintVerificationStatus, getCrossmintVerificationStatus } from '@/api/crossmint';
import { getBaseReservoirURL, getFloorPrices, getTokensMetadata } from '@/api/reservoir';
import ClaimExtensionContract, {
  StorageProtocol,
  StorageProtocolGateway,
} from '@/classes/claimExtensionContract';
import ERC20Contract from '@/classes/erc20Contract';
import {
  DELEGATION_REGISTRY_ADDRESS_V1,
  DELEGATION_REGISTRY_ADDRESS_V2,
  EXTENSION_TRAITS,
  FEE_PER_MERKLE_MINT,
  FEE_PER_MINT,
  MATIC_FEE_PER_MERKLE_MINT,
  MATIC_FEE_PER_MINT,
  NULL_ADDRESS,
} from '@/common/constants';
import { useDelegates } from '@/composables/delegate';
import { resolveENSFromBadges } from '@/lib/address';
import { formatAddress } from '@/lib/claimUtil';
import { formatUSDCentsToDollars } from '@/lib/currency';

export enum ClaimType {
  ERC721 = 'erc721',
  ERC1155 = 'erc1155',
}

export type Creator = {
  id: number;
  address: string;
  name: string;
  image: string | null;
  twitterUrl?: string;
};

export type PricingDisplayStrategy = 'show-all' | 'show-fiat-only' | 'show-fiat-first';

export type PriceDisplayPublicData = {
  strategy: string;
  fixedFiatCost?: string;
};

export type PricingDisplay = {
  strategy: PricingDisplayStrategy;
  fixedFiatCost?: BigNumber;
};

export type MintLimitType = 'whitelist' | 'limited' | 'open';

export type WalletRestrictionType = 'allowlist-limit' | 'wallet-limit' | 'no-limit';

export enum MintingRestriction {
  NONE = 'None',
  ALLOWLIST = 'Allowlist',
  IYK_TAP = 'IykTap',
  REDEMPTION_CODES = 'RedemptionCodes',
}

export enum SignatureSchema {
  NONE = 'None',
  IYK_TAP = 'IykTap',
  REDEMPTION_CODES = 'RedemptionCodes',
}

export enum SigMintError {
  NO_SECRET = 1,
  BAD_SECRET,
  EXPIRED_SIGNATURE,
  SERVER_ERROR,
  USED_OTP,
}

export type MediaBackgroundConfig = {
  angle?: number;
  image?: string;
  type?: string;
};

interface ClientLogos {
  /**
   * url for the single main logo to display at top of the page on mobile; side of the page on desktop.
   * has the `.m-claim__logo.m-claim__logo--main` classes applied.
   **/
  main?: string;
  /**
   * array of partner logos. has the `.m-claim__logo.m-claim__logo--partner` classes applied.
   * appears in the array order
   **/
  partners?: {
    /** html id applied to the image element */
    id: string;
    /** url for the image */
    url: string;
  }[];
}

/** visual customisations for the collector side */
export interface ClientTheme {
  /** background display for the claim app. can be an image URL, video URL, or a background color */
  appBackground?: {
    /** url for the image */
    image?: string;
    /** url for the src property of a video element */
    video?: string;
    /** css-readable color string */
    backgroundColor: string;
  };
  mediaBackground?: MediaBackgroundConfig;
  /** theme colors used in the collector app */
  colors?: {
    /** css-readable color string for primary color accents. defaults to 'white' if unset. */
    themePrimary: string;
    /** css-readable color string for secondary color accents (e.g.: provenance headers). defaults to white @ 50% opacity if unset. */
    themeSecondary: string;
  };
  /** emojis to display on checkout. */
  checkoutEmojis?: string[];
  /** custom animation to move around the page */
  animation?: {
    /** url for image source */
    source: string;
    /** width of animation in px */
    width: number;
    /** height of animation in px */
    height: number;
    /** whether animations should be played on mobile. defaults to false. */
    playOnMobile?: boolean;
  };
  /** custom text for various buttons and elsewhere */
  text?: {
    /** text for post-mint message */
    postMint?: string;
  };
  /** custom embedded video on the description page */
  videoEmbed?: {
    /** url for the video. should be a direct link (e.g.: *.mp4) or a youtube link */
    src: string;
    /** image to display in the embed */
    poster: string;
    /** string to display in the embed */
    title: string;
  };
  /** logos to display on the claim page */
  logos?: ClientLogos;
}

export type InstanceResponse = {
  id: number;
  appId: number;
  slug: string;
  creator: Creator;
  publicData: {
    name: string;
    image: string;
    description: string;
    audienceId: number | null;
    merkleTreeId: number | undefined;
    extensionAddress: string;
    overrideMintExtensionAddress: string;
    creatorContractAddress: string;
    claimIndex: number;
    claimType: string;
    animation?: string;
    network?: number;
    clientTheme?: ClientTheme;
    crossmintClientId?: string;
    priceDisplay?: PriceDisplayPublicData;
    erc20?: string;
    signatureSchema: SignatureSchema;
  };
};

export type SchemaInstanceResponse = {
  id: number;
  appId: number;
  slug: string;
  creator: Creator;
  publicData: {
    asset: {
      name: string;
      image: string;
      // eslint-disable-next-line camelcase
      image_preview?: string;
      // eslint-disable-next-line camelcase
      animation_url?: string;
      // eslint-disable-next-line camelcase
      animation_preview?: string;
    };
    claimIndex?: number;
    description: string;
    network: number;
    contract: {
      contractAddress: string;
      spec: 'ERC721' | 'ERC1155';
    };
    instanceAllowlist?: {
      merkleTreeId: number;
    };
    extensionAddress721: {
      value: string;
    };
    extensionAddress1155: {
      value: string;
    };
    mintPrice: {
      value: string;
      erc20: string;
    };
    crossmintClientId?: string;
    mintingRestriction: MintingRestriction;
    // Client formatting configuration
    clientTheme?: {
      background?: {
        color: string;
        image: string;
        video: string;
      };
      checkoutEmojis?: string[];
      colors?: {
        primary: string;
        secondary: string;
      };
      logos: {
        main?: string;
        partners?: {
          id: string;
          url: string;
        }[];
      };
      lowerAnimation?: {
        height: number;
        playOnMobile: boolean;
        source: string;
        width: number;
      };
      mediaBackground?: MediaBackgroundConfig;
      text?: {
        postMint: string;
      };
      videoEmbed?: {
        src: string;
        poster: string;
        title: string;
      };
      pricingDisplay?: PriceDisplayPublicData;
    };
  };
};

export const useClaimStore = defineStore('claim', () => {
  ///
  // STATE
  //
  // Note: Setting a default state is required. Many
  // values are overwritten at time of initialization but the
  // typings they establish are important.
  //
  // If after initialization a value is guaranteed to
  // not be `undefined`, set a default value here.
  ///

  // instance data
  const id = ref(0);
  const appId = ref(0);
  const name = ref('');
  const image = ref('');
  const description = ref('');
  const slug = ref('');
  const creator = ref<Creator>({} as Creator);
  const audienceId = ref<number | null>(null);
  const merkleTreeId = ref<number | undefined>(undefined);
  const extensionAddress = ref('');
  const overrideMintExtensionAddress = ref('');
  const creatorContractAddress = ref('');
  const claimIndex = ref(0);
  const claimType = ref(ClaimType.ERC721);
  const animation = ref<string | undefined>();
  const fallbackProvider = ref<string>();
  // default to mainnet (which we do anyway -- but we avoid undefined when we declare it here)
  const networkId = ref<number>(1);
  const pricingDisplay = ref<PricingDisplay>({ strategy: 'show-all' });
  const crossmintClientId = ref<string>('');
  const signatureSchema = ref<SignatureSchema>();
  const _clientTheme = ref<ClientTheme>({} as ClientTheme);

  // on-chain claim data
  const total = ref(0);
  const totalMax = ref<number | null>(null);
  const walletMax = ref<number | null>(null);
  const merkleInfo = ref<IMerkleInfo[]>([]);
  const startDate = ref<Date | null>(null);
  const endDate = ref<Date | null>(null);
  const storageProtocol = ref(StorageProtocol.NONE);
  const merkleRoot = ref(formatBytes32String(''));
  const location = ref('');
  const tokenId = ref<BigNumber | null>(null);
  const cost = ref(BigNumber.from(0));
  const erc20Address = ref<string>('');

  // store data
  const initializedInstanceData = ref(false);
  const initialized = ref(false);
  const contract = ref<ClaimExtensionContract>({} as ClaimExtensionContract);
  const overrideMintContract = ref({} as ClaimExtensionContract);
  const erc20Contract = ref({} as ERC20Contract);
  const erc20Symbol = ref<string>('ETH');
  const erc20Decimals = ref<number>(18);
  const erc20ApprovedSpend = ref<BigNumber>(BigNumber.from(0));
  const isLoadingWeb3State = ref(false);

  // BEGIN Signature Mint Data
  // Signature Mints: IYK data
  const sigMintError = ref<SigMintError | undefined>();
  const iykRef = ref<string | undefined>();
  const iykTimeoutMessage = ref<string | undefined>();
  const iykTimeoutSignature = ref<string | undefined>();
  const iykTimeoutNonce = ref<number | undefined>();

  // Signature Mints: Redemption Codes data
  const redemptionCode = ref<string | undefined>();
  const redemptionCodeValidated = ref<boolean>(false);
  // END Signature Mint Data

  const tokensToPurchase = ref(1);
  const ethToUsdRate = ref(1);
  const erc20ToUsdRate = ref(1);
  /** ordered floor prices for contract could be either 1155 or 721 */
  const floorPrices = ref<Array<[key: string, price: number]>>([]);
  /** map of tokenId to price could be either 1155 or 721 */
  const tokenPrices = ref<{ [key: string]: number }>();
  /** tokenId for lowest price for collection */
  const floorPriceTokenId = ref<BigNumber | null>(null);

  /**
   * The number of tokens the current wallet has minted.
   * - `null` if the claim has no wallet max or allow list (data is not tracked)
   * - otherwise the number of tokens minted by the wallet
   */
  const tokensMintedByWallet = ref<number | null>(null);
  const activeNetwork = ref<number>();
  const walletAddress = ref<string>();
  const ensOrFormattedWalletAddress = ref<string>();
  const creatorEnsOrFormattedWalletAddress = ref<string>();
  const balance = ref<BigNumber>();
  const isProviderAvailable = ref(false);
  const isCrossmintVerified = ref(false);
  const isResetingMerkleData = ref(false);

  /**
   * The number of allowlist mints available
   * for the current wallet
   */
  const claimableMintIndices = ref<number[]>([]);
  const claimableMerkleProofs = ref<string[][]>([]);

  /**
   * Wallet that user has selected to mint for on behalf of their logged in wallet (Delegation Registry). To learn more: https://delegate.cash/
   * This value is defaulted to the logged in wallet address.
   */
  const mintForWallet = ref<string | null>();

  /**
   * Composable to compute eligible vault wallets from delegation registry
   */
  const {
    isLoading: isDelegationLoading,
    eligibleVaultWallets,
    error: delegationError,
    hasPotentiallyMisconfiguredDelegations,
  } = useDelegates(
    networkId,
    walletAddress,
    extensionAddress,
    DELEGATION_REGISTRY_ADDRESS_V1,
    DELEGATION_REGISTRY_ADDRESS_V2,
    {
      checkWalletsEligibility,
    }
  );
  /**
   * Whether to show the "potentially misconfigured delegations" warning
   */
  const showBadDelegationsWarning = computed(() => {
    return !!hasPotentiallyMisconfiguredDelegations.value && !isDelegationLoading.value;
  });

  ///
  // Fiat prices
  ///
  const priceSubtotalInFiat = ref<BigNumber>(BigNumber.from(0));
  const processingFeesInFiat = ref<BigNumber>(BigNumber.from(0));
  const finalPriceInFiat = ref<BigNumber>(BigNumber.from(0));
  const loadingFiatPrices = ref(true);

  ///
  // ACTIONS
  ///
  async function initialize(
    instance: InstanceResponse | SchemaInstanceResponse,
    fallbackProvider?: string
  ) {
    if (
      !(instance as InstanceResponse).publicData?.claimType &&
      !(instance as SchemaInstanceResponse).publicData?.contract.spec
    ) {
      // Instance data not initialized yet
      throw new Error('Temporarily unavailable');
    }
    if ((instance as InstanceResponse).publicData?.claimType) {
      _initializeInstanceData(instance as InstanceResponse);
    } else {
      _initializeInstanceSchemaData(instance as SchemaInstanceResponse);
    }
    initializedInstanceData.value = true;

    await _initializeProvider(fallbackProvider);

    contract.value = new ClaimExtensionContract(
      networkId.value,
      extensionAddress.value,
      creatorContractAddress.value,
      claimIndex.value
    );

    // If we have override mint contract address, set that
    if (overrideMintExtensionAddress.value) {
      overrideMintContract.value = new ClaimExtensionContract(
        networkId.value,
        overrideMintExtensionAddress.value,
        creatorContractAddress.value,
        claimIndex.value
      );
    }
    const promises: any[] = [];
    if (erc20Address.value) {
      erc20Contract.value = new ERC20Contract(networkId.value, erc20Address.value);
      promises.push(erc20Contract.value.getERC20Symbol());
      promises.push(erc20Contract.value.getERC20Decimals());
    }

    promises.push(refreshOnChainClaim());

    const results = await Promise.all(promises);
    // Populate erc20 symbol and decimals from results
    if (erc20Address.value) {
      erc20Symbol.value = results[0];
      erc20Decimals.value = results[1];
    }

    // If the claim is just ETH, but is on Optimism, set the symbol to "OETH"
    if (!erc20Address.value && networkId.value === 10) {
      erc20Symbol.value = 'OETH';
    } else if (!erc20Address.value && networkId.value === 137) {
      erc20Symbol.value = 'MATIC';
    }

    // Initialize ENS and Twitter for creator of the claim
    resolveENSFromBadges(creator.value.address || '').then((resolvedEns) => {
      creatorEnsOrFormattedWalletAddress.value =
        resolvedEns || formatAddress(creator.value.address || '');
    });
    getTwitterHandle(creator.value.address || '').then((handle) => {
      creator.value.twitterUrl = handle;
    });

    // Setting exchange rates is asynchronous (non-critical path)
    _setPriceRates();
    setInterval(_setPriceRates, 30000);

    // Setting floor prices is asynchronous (non-critical path)
    setFloorPrices();

    // Crossmint initialization is async (non-critical path)
    _initializeCrossmint();

    if (signatureSchema.value === SignatureSchema.IYK_TAP) {
      await setIykClaimSignatureData();
    }

    initialized.value = true;
  }

  async function _initializeCrossmint() {
    if (crossmintClientId.value) {
      const verifyResponse = await getCrossmintVerificationStatus(crossmintClientId.value);
      const sellerStatus = verifyResponse?.verificationStatus?.seller?.status;
      const collectionStatus = verifyResponse?.verificationStatus?.collection?.status;
      isCrossmintVerified.value =
        (sellerStatus === CrossmintVerificationStatus.VERIFIED &&
          collectionStatus === CrossmintVerificationStatus.VERIFIED) ||
        networkId.value === 5;
    }
  }

  async function _setPriceRates() {
    let currency = 'ETH';
    if (networkId.value === 137) {
      currency = 'MATIC';
    }

    ethToUsdRate.value = (await getEthToUsdRate(currency)) ?? 0;
    if (erc20Address.value) {
      erc20ToUsdRate.value = (await getERC20ToUSDRate(erc20Symbol.value)) ?? 0;
    }
  }

  async function setFloorPrices() {
    try {
      // floor entries are returned in order of tokenId not price
      const tokensOrdered =
        (await getFloorPrices(creatorContractAddress.value, networkId.value)) ?? {};
      tokenPrices.value = tokensOrdered;
      const tokensByPrice = Object.entries(tokensOrdered).sort((a, b) => a[1] - b[1]) ?? [];
      const values = await getTokensMetadata(
        creatorContractAddress.value,
        tokensByPrice,
        networkId.value
      );
      // @dev ideally check if rules.numbered is true then check for regex match

      floorPrices.value = values
        .filter((token) => {
          if (!token?.token || typeof token?.token?.name !== 'string') {
            return false;
          }
          // don't currently have access to claim.rules but can check if it's not 1155
          // for edition claim, token.token.name will be `name #numbered`
          return (
            (!is1155.value && token.token.name.startsWith(`${name.value} #`)) ||
            token.token.name.trim() === name.value.trim()
          );
        })
        .map((value) => {
          return [value.token.tokenId, value.market.floorAsk.price.amount.decimal];
        });
    } catch (e) {
      // Do nothing on failure
    }
  }

  function getFloorPrice() {
    if (tokenId.value && tokenPrices.value) {
      const value = tokenPrices.value[tokenId.value.toString()];
      floorPriceTokenId.value = tokenId.value;
      return parseEther(value?.toString() || '0');
    } else if (!tokenId.value && floorPrices.value && floorPrices.value.length > 0) {
      if (!is1155.value) {
        floorPriceTokenId.value = BigNumber.from(floorPrices.value[0][0]);
      }
      return parseEther(floorPrices.value[0][1]?.toString());
    }

    return BigNumber.from(0);
  }

  async function _initializeProvider(fallbackProvider?: string) {
    await detectManifoldEthereumProvider({ initialized: false });
    if ((window.ManifoldEthereumProvider as any)._isInitialized !== undefined) {
      // Legacy version of connect widget
      await (window.ManifoldEthereumProvider as any).initialize(networkId.value, fallbackProvider);
    } else {
      await window.ManifoldEthereumProvider.initialize({
        network: networkId.value,
        fallbackHost: fallbackProvider,
        browserProviderTimeout: 500,
        browserProviderIgnoreDisconnect: true,
      });
    }
    const provider = window.ManifoldEthereumProvider.provider();
    const signingProvider = window.ManifoldEthereumProvider.provider(true);

    if (signingProvider && provider !== signingProvider) {
      isProviderAvailable.value = false;
    } else {
      isProviderAvailable.value = !!provider;
    }

    setProviderEventsHandler(async () => {
      const eth = window.ManifoldEthereumProvider;
      const provider = eth.provider();
      const signingProvider = eth.provider(true);
      const address = eth.selectedAddress();
      const chainId = eth.chainId();

      walletAddress.value = address;
      // Initialize ENS for connected wallet (buyer) of the claim
      const resolvedEns = await resolveENSFromBadges(address || '');
      ensOrFormattedWalletAddress.value = resolvedEns || formatAddress(address || '');
      if (erc20Address.value) {
        erc20ApprovedSpend.value = await erc20Contract.value.getAllowance(
          extensionAddress.value,
          walletAddress.value!
        );
      }
      try {
        balance.value = await eth.provider()?.getBalance(address || '');
      } catch {
        balance.value = undefined;
      }

      // Default mintForWallet to walletAddress, this value can change if user selects a different wallet to mint for on UI
      mintForWallet.value = walletAddress.value;

      /**
       * NOTE: If a browser provider like MetaMask is available
       * chainId will be present, however with WalletConnect
       * there is often no provider available and therefore no chainId
       * either. We can only rely on a the claim's network.
       */
      activeNetwork.value = chainId || networkId.value;

      if (signingProvider && provider !== signingProvider) {
        isProviderAvailable.value = false;
      } else {
        isProviderAvailable.value = !!provider;
      }
    });
  }

  function _initializeInstanceData(instance: InstanceResponse) {
    // TODO: use zod, this is all a lie
    id.value = instance.id;
    appId.value = instance.appId;
    slug.value = instance.slug;
    creator.value = instance.creator;

    const publicData = instance.publicData;
    name.value = publicData.name;
    image.value = publicData.image;
    description.value = publicData.description;
    merkleTreeId.value = publicData.merkleTreeId;
    extensionAddress.value = publicData.extensionAddress;
    overrideMintExtensionAddress.value = publicData.overrideMintExtensionAddress;
    creatorContractAddress.value = publicData.creatorContractAddress;
    claimIndex.value = publicData.claimIndex;
    claimType.value = publicData.claimType.toLowerCase() as ClaimType;
    animation.value = publicData.animation;
    networkId.value = publicData.network || 1;
    pricingDisplay.value = {
      strategy: (publicData.priceDisplay?.strategy || 'show-all') as PricingDisplayStrategy,
      fixedFiatCost: parseUnits(publicData.priceDisplay?.fixedFiatCost || '0', 2),
    };
    erc20Address.value =
      instance.publicData.erc20 && instance.publicData.erc20 !== NULL_ADDRESS
        ? instance.publicData.erc20
        : '';

    crossmintClientId.value = publicData.crossmintClientId || '';

    signatureSchema.value = publicData.signatureSchema
      ? publicData.signatureSchema
      : SignatureSchema.NONE;

    // rely on getters to handle the default values
    _clientTheme.value = publicData.clientTheme || {};
  }

  function _initializeInstanceSchemaData(instance: SchemaInstanceResponse) {
    // TODO: use zod, this is all a lie
    id.value = instance.id;
    appId.value = instance.appId;
    slug.value = instance.slug;
    creator.value = instance.creator;

    const publicData = instance.publicData;
    name.value = publicData.asset.name;
    image.value = publicData.asset.image_preview || publicData.asset.image;
    animation.value = publicData.asset.animation_preview || publicData.asset.animation_url;
    description.value = publicData.description;
    if (instance.publicData.contract.spec === 'ERC721') {
      extensionAddress.value = instance.publicData.extensionAddress721.value;
    } else {
      extensionAddress.value = instance.publicData.extensionAddress1155.value;
    }
    claimType.value = publicData.contract.spec.toLowerCase() as ClaimType;
    creatorContractAddress.value = publicData.contract.contractAddress;
    claimIndex.value = publicData.claimIndex ?? instance.id;
    networkId.value = publicData.network;
    erc20Address.value =
      publicData.mintPrice.erc20 && publicData.mintPrice.erc20 !== NULL_ADDRESS
        ? publicData.mintPrice.erc20
        : '';
    if (publicData.mintingRestriction === MintingRestriction.ALLOWLIST) {
      merkleTreeId.value = publicData.instanceAllowlist?.merkleTreeId;
    }
    crossmintClientId.value = publicData.crossmintClientId || '';
    pricingDisplay.value = publicData.clientTheme?.pricingDisplay
      ? {
          strategy: (publicData.clientTheme.pricingDisplay.strategy ||
            'show-all') as PricingDisplayStrategy,
          fixedFiatCost: parseUnits(publicData.clientTheme.pricingDisplay.fixedFiatCost || '0', 2),
        }
      : {
          strategy: 'show-all',
          fixedFiatCost: BigNumber.from(0),
        };

    if (
      [MintingRestriction.IYK_TAP, MintingRestriction.REDEMPTION_CODES].includes(
        publicData.mintingRestriction
      )
    ) {
      signatureSchema.value = publicData.mintingRestriction as unknown as SignatureSchema;
    } else {
      signatureSchema.value = SignatureSchema.NONE;
    }

    const clientTheme: ClientTheme = {
      appBackground: publicData.clientTheme?.mediaBackground
        ? {
            image: publicData.clientTheme?.mediaBackground.image,
            backgroundColor: 'transparent',
          }
        : undefined,
      mediaBackground: publicData.clientTheme?.mediaBackground,
      colors: publicData.clientTheme?.colors
        ? {
            themePrimary: publicData.clientTheme?.colors.primary,
            themeSecondary: publicData.clientTheme?.colors.secondary,
          }
        : undefined,
      checkoutEmojis: publicData.clientTheme?.checkoutEmojis,
      animation: publicData.clientTheme?.lowerAnimation,
      text: publicData.clientTheme?.text,
      videoEmbed: publicData.clientTheme?.videoEmbed,
      logos: publicData.clientTheme?.logos,
    };

    // rely on getters to handle the default values
    _clientTheme.value = clientTheme;
  }

  async function refreshOnChainClaim() {
    const onChainData = await contract.value.getClaim(claimType.value);
    total.value = onChainData.total;
    totalMax.value = onChainData.totalMax;
    walletMax.value = onChainData.walletMax;
    if (onChainData.startDate && onChainData.startDate.getTime() !== 0) {
      startDate.value = onChainData.startDate;
    }
    if (onChainData.endDate && onChainData.endDate?.getTime() !== 0) {
      endDate.value = onChainData.endDate;
    }
    storageProtocol.value = onChainData.storageProtocol;
    merkleRoot.value = onChainData.merkleRoot;
    // If we an active claim with a merkleRoot (allowlist claim) but missing
    // a merkleTreeId (because) it's using a legacy audience or due to another error,
    // the claim has to be inactive because we cannot generate a proof
    if (
      onChainData.merkleRoot !== formatBytes32String('') &&
      !merkleTreeId.value &&
      status.value === 'active' &&
      isSoldOut.value === false
    ) {
      endDate.value = new Date(0);
    }
    location.value = onChainData.location;
    tokenId.value = onChainData.tokenId;
    cost.value = onChainData.cost;
  }

  async function fetchMerkleInfo(address: string | undefined | null) {
    if (!address) {
      return [];
    }
    if (merkleTreeId.value) {
      return await collectors.getMerkleInfos(merkleTreeId.value, address);
    } else {
      return [];
    }
  }

  async function refreshWeb3State() {
    isLoadingWeb3State.value = true;

    try {
      if (hasAllowlist.value) {
        isResetingMerkleData.value = true;
        merkleInfo.value = await fetchMerkleInfo(mintForWallet.value);
        const { mintIndices, merkleProofs } = await _fetchMintIndices(merkleInfo.value);
        claimableMintIndices.value = mintIndices;
        claimableMerkleProofs.value = merkleProofs;
        isResetingMerkleData.value = false;
      }

      refreshOnChainClaim();
      _refreshNumTokensMintedByWallet();
      _refreshAmountApproved();

      // TODO: how to scope this refresh? identity-widget
      // doesn't need refreshing.
      window.dispatchEvent(new Event('m-refresh-widgets'));
    } finally {
      isLoadingWeb3State.value = false;
    }
  }

  // Helper function for resetting merkle data..
  async function resetMerkleData() {
    if (hasAllowlist.value) {
      isResetingMerkleData.value = true;
      merkleInfo.value = await fetchMerkleInfo(mintForWallet.value);
      const { mintIndices, merkleProofs } = await _fetchMintIndices(merkleInfo.value);
      claimableMintIndices.value = mintIndices;
      claimableMerkleProofs.value = merkleProofs;
      isResetingMerkleData.value = false;
    }
  }

  /**
   * Check if a certain wallet is eligible to claim one or more tokens
   */
  async function checkWalletsEligibility(addresses: string[]): Promise<string[]> {
    // only check for eligibility if there's an allowlist (This is constrained on contract-level)
    const eligibleWallets: string[] = [];
    if (walletRestriction.value !== 'allowlist-limit') {
      return eligibleWallets;
    }

    for (const address of addresses) {
      const merkleInfo = await fetchMerkleInfo(address);
      const { mintIndices } = await _fetchMintIndices(merkleInfo);
      const allowedMintQuantity = _getClaimableQuantity(walletRestriction.value, mintIndices);
      if (allowedMintQuantity > 0) {
        eligibleWallets.push(address);
      }
    }
    return eligibleWallets;
  }

  // Set IYK Signature Claim Data
  async function setIykClaimSignatureData() {
    const params = new URLSearchParams(document.location.search);
    iykRef.value = params.get('iykRef') || '';

    if (!iykRef.value) {
      setSigMintError(SigMintError.NO_SECRET);
      return;
    }

    try {
      const response: {
        status: number;
        message: string;
        signature: string;
        nonce: number;
      } = await signatureApi.iykValidate({
        instanceId: id.value,
        iykRef: iykRef.value,
      });

      if (response.status === 400) {
        setSigMintError(SigMintError.BAD_SECRET);
      }

      iykTimeoutMessage.value = response.message;
      iykTimeoutSignature.value = response.signature;
      iykTimeoutNonce.value = response.nonce;
      updateIykTimeoutCountdown();
    } catch (e) {
      setSigMintError(SigMintError.SERVER_ERROR);
    }
  }

  function setSigMintError(error: SigMintError) {
    sigMintError.value = error;
  }

  function setRedemptionCode(code: string | undefined = undefined) {
    redemptionCode.value = code;
    if (code) {
      redemptionCodeValidated.value = true;
    } else {
      redemptionCodeValidated.value = false;
    }
  }

  async function _refreshAmountApproved() {
    if (erc20Address.value) {
      erc20ApprovedSpend.value = await erc20Contract.value.getAllowance(
        extensionAddress.value,
        walletAddress.value!
      );
    }
  }

  ///
  // Private Methods
  ///

  async function _refreshNumTokensMintedByWallet() {
    if (!mintForWallet.value) {
      return null;
    }

    if (walletRestriction.value === 'allowlist-limit') {
      const availableIndices = merkleInfo.value.map((claimMerkleInfo) => claimMerkleInfo.value);
      const usedIndices = await contract.value.checkMintIndices(availableIndices);

      tokensMintedByWallet.value = usedIndices.filter(
        (mintedIndice) => mintedIndice === true
      ).length;
    } else if (walletRestriction.value === 'wallet-limit') {
      tokensMintedByWallet.value = await contract.value.getTotalMints(mintForWallet.value);
    } else {
      // claims with no wallet restrictions do not track
      // the amount of tokens minted per wallet.
      tokensMintedByWallet.value = null;
    }
  }

  function _getClaimableQuantity(
    walletRestriction: WalletRestrictionType,
    claimableMintIndices: number[]
  ) {
    const totalSupply = totalMax.value === null ? Infinity : totalMax.value;

    // Bound by 0 as can be negative if creator updates total supply post mint
    const availableSupply = Math.max(0, totalSupply - total.value);

    let availableForWallet;
    switch (walletRestriction) {
      case 'allowlist-limit':
        availableForWallet = claimableMintIndices.length;
        break;
      case 'wallet-limit':
        // 1. can be negative if creator updates per wallet limit
        // 2. if we have a wallet limit, we always have a value for tokensMinted
        availableForWallet = Math.max(0, (walletMax.value as number) - tokensMintedByWallet.value!);
        break;
      case 'no-limit':
        availableForWallet = Infinity;
        break;
    }

    return Math.min(availableSupply, availableForWallet);
  }

  async function _fetchMintIndices(merkleInfo: IMerkleInfo[]) {
    if (!hasAllowlist.value) {
      return { mintIndices: [], merkleProofs: [] };
    }

    const mintIndices = merkleInfo.map((claimMerkleInfo) => claimMerkleInfo.value);
    const mintIndicesStatus = await contract.value.checkMintIndices(mintIndices);
    const claimableMerkleInfo = merkleInfo.filter((_, index) => !mintIndicesStatus[index]);

    return {
      mintIndices: claimableMerkleInfo.map((claimMerkleInfo) => claimMerkleInfo.value),
      merkleProofs: claimableMerkleInfo.map((claimMerkleInfo) => claimMerkleInfo.merkleProof),
    };
  }

  /** called when giving a baseline of the prices/before the crossmint form is displayed */
  function initializeFiatPrices() {
    priceSubtotalInFiat.value = costInFiat.value.mul(tokensToPurchase.value);
    finalPriceInFiat.value = priceSubtotalInFiat.value.add(
      manifoldFeeInFiat.value.mul(tokensToPurchase.value)
    );
    loadingFiatPrices.value = false;
  }

  /**
   * Set the loading state while we fetch the fiat prices from an external widget
   */
  function fetchingFiatPricesExternally() {
    loadingFiatPrices.value = true;
  }

  /**
   * Manually set the fiat prices that came from an external widget
   *
   * @param priceSubtotalWithManifoldFees
   * @param finalPrice The final price with all fees, including the payment processor fees
   */
  function updateFiatPricesFromExternalWidget(
    priceSubtotalWithManifoldFees: BigNumber,
    finalPrice: BigNumber
  ) {
    if (isFreeClaim.value && !hasSecondary.value) {
      priceSubtotalInFiat.value = BigNumber.from(0);
    } else if (_hasFixedFiatCost.value) {
      priceSubtotalInFiat.value = costInFiat.value.mul(tokensToPurchase.value);
    } else {
      priceSubtotalInFiat.value = priceSubtotalWithManifoldFees.sub(manifoldFeeInFiat.value);
    }

    processingFeesInFiat.value = finalPrice
      .sub(manifoldFeeInFiat.value)
      .sub(priceSubtotalInFiat.value);
    finalPriceInFiat.value = finalPrice;
    loadingFiatPrices.value = false;
  }

  function updateIykTimeoutCountdown() {
    if (iykTimeoutNonce.value) {
      if (Math.floor(Date.now() / 1000) < iykTimeoutNonce.value) {
        requestAnimationFrame(updateIykTimeoutCountdown);
      } else {
        setSigMintError(SigMintError.EXPIRED_SIGNATURE);
      }
    }
  }

  /**
   * whether there is a fixed cost in fiat that we should calculate with.
   * checks if costInFiat is non-zero and it's not secondary
   */
  const _hasFixedFiatCost = computed<boolean>(() => {
    return !costInFiat.value.eq(0) && !hasSecondary.value;
  });

  function setMintForWallet(address: string) {
    mintForWallet.value = address;
  }

  ///
  // GETTERS
  ///
  const hasAllowlist = computed(() => {
    return merkleRoot.value !== formatBytes32String('');
  });

  const isConnected = computed(() => {
    return Boolean(walletAddress.value);
  });

  const hasWalletLimit = computed(() => {
    return walletMax.value !== null;
  });

  const isUnlimitedSupply = computed(() => {
    return totalMax.value === null;
  });

  const canMint = computed(() => {
    return hasTokensToPurchase.value || tokensToPurchase.value <= claimableQuantity.value;
  });

  // claim has ended, network is supported, and there is a floor price
  const hasSecondary = computed(() => {
    return (
      (status.value === 'ended' || (!!totalMax.value && total.value >= totalMax.value)) &&
      getBaseReservoirURL(networkId.value) &&
      (getFloorPrice() > BigNumber.from(0) || (floorPrices.value && floorPrices.value.length > 0))
    );
  });

  const status = computed(() => {
    const now = Date.now();

    if (startDate.value && startDate.value.getTime() > now) {
      return 'not-started';
    } else if (endDate.value && endDate.value.getTime() < now) {
      return 'ended';
    } else {
      return 'active';
    }
  });

  const isSoldOut = computed(() => {
    if (totalMax.value === null) {
      return false;
    }

    // total can exceed totalMax if creator updates supply
    return total.value > 0 && total.value >= totalMax.value;
  });

  const isPayable = computed(() => {
    return cost.value.eq(0);
  });

  ///
  // Client theme display
  //
  // getters for clientTheme-specific values with defaults. using object.assign
  // instead of spread since config can be undefined
  ///
  const clientThemedText = computed<Required<ClientTheme>['text']>(() => {
    return _clientTheme.value.text || {};
  });

  /** returns `null` if there is no video embed/the video embed is malformed */
  const clientVideoEmbed = computed<Required<ClientTheme>['videoEmbed'] | null>(() => {
    const videoEmbed: Partial<Required<ClientTheme>['videoEmbed']> = Object.assign(
      {
        title: '',
        poster: '',
      },
      _clientTheme.value.videoEmbed
    );
    if (!videoEmbed.src) {
      return null;
    }
    return videoEmbed as Required<ClientTheme>['videoEmbed'];
  });

  /** array of logos to disply on the page */
  const clientLogos = computed<Required<ClientLogos> | null>(() => {
    const logos = _clientTheme.value.logos;
    // if no logos object, or both main and partners are empty, return null
    if (!logos || !(logos.main || logos.partners?.length)) {
      return null;
    }

    const result: Required<ClientLogos> = {
      main: logos.main || '',
      partners: logos.partners || [],
    };

    if (result.partners.length) {
      // filter out empty partner strings
      const filteredClientLogos = result.partners.reduce(
        (acc, key): Required<ClientLogos>['partners'] => {
          if (!key || !key.url) {
            return acc;
          }
          acc.push(key);
          return acc;
        },
        [] as Required<ClientLogos>['partners']
      );
      result.partners = filteredClientLogos;
    }

    return result;
  });

  const checkoutEmojis = computed<Required<ClientTheme>['checkoutEmojis']>(() => {
    if (!_clientTheme.value.checkoutEmojis || !_clientTheme.value.checkoutEmojis.length) {
      return ['🎉'];
    }
    return _clientTheme.value.checkoutEmojis;
  });

  const clientColors = computed<Required<ClientTheme>['colors']>(() => {
    return Object.assign(
      {
        themePrimary: 'white',
        themeSecondary: 'hsl(0deg 0% 100% / 50%)',
      },
      _clientTheme.value.colors
    );
  });

  const appBackground = computed<Required<ClientTheme>['appBackground']>(() => {
    return Object.assign(
      {
        backgroundColor: 'black',
        image: '',
        video: '',
      },
      _clientTheme.value.appBackground
    );
  });

  const mediaBackground = computed<Required<ClientTheme>['mediaBackground']>(() => {
    // DNE or is empty object
    if (
      !_clientTheme.value.mediaBackground ||
      Object.keys(_clientTheme.value.mediaBackground).length === 0
    ) {
      return {};
    }
    return _clientTheme.value.mediaBackground;
  });

  const clientAnimation = computed<Required<Required<ClientTheme>['animation']> | null>(() => {
    if (!_clientTheme.value.animation || !_clientTheme.value.animation.source) {
      return null;
    }
    return Object.assign({ playOnMobile: false }, _clientTheme.value.animation);
  });

  const displayFiatPrice = computed(() => {
    return (
      pricingDisplay.value.strategy === 'show-fiat-first' ||
      pricingDisplay.value.strategy === 'show-fiat-only'
    );
  });

  const hideCryptoPrice = computed(() => {
    return pricingDisplay.value.strategy === 'show-fiat-only';
  });

  const isFreeClaim = computed(() => {
    return cost.value.eq(0);
  });

  const metadataURL = computed(() => {
    // Onchain metadata asset
    if (URLs.parseOnChainMetadata(location.value)) {
      return '';
    }
    return `${StorageProtocolGateway[storageProtocol.value]}${location.value}`;
  });

  const onChainImage = computed(() => {
    const onChainMetadata = URLs.parseOnChainMetadata(location.value);
    if (onChainMetadata && (onChainMetadata.image || onChainMetadata.image_url)) {
      return onChainMetadata.image || onChainMetadata.image_url;
    }
    return '';
  });

  const is721 = computed(() => {
    return claimType.value === 'erc721';
  });

  const is1155 = computed(() => {
    return claimType.value === 'erc1155';
  });

  /**
   * A claim can be restricted by an allowlist (0x1 is allowed 4 mints)
   * or by wallet limit (ie: walletMax, '2 tokens max per wallet').
   * Otherwise there is no limit.
   *
   * Note: that a claim may still have a supply cap. This just
   * describes the wallet restriction.
   */
  const walletRestriction = computed((): WalletRestrictionType => {
    if (hasAllowlist.value) {
      return 'allowlist-limit';
    } else if (walletMax.value) {
      return 'wallet-limit';
    } else {
      return 'no-limit';
    }
  });

  /**
   * Returns the number of tokens that can be claimed by the current wallet.
   * This is the minimum of the available supply and the amount available for
   * the wallet.
   *
   * @returns number, including Infinity
   */
  const claimableQuantity = computed(() => {
    return _getClaimableQuantity(walletRestriction.value, claimableMintIndices.value);
  });

  const hasTokensToPurchase = computed(() => {
    return claimableQuantity.value !== 0 || hasSecondary.value;
  });

  const hasntMintedAnyTokens = computed(() => {
    return tokensMintedByWallet.value === 0 || tokensMintedByWallet.value === null;
  });

  const isTryingToMintMoreTokensThanPossible = computed(() => {
    return tokensToPurchase.value > claimableQuantity.value;
  });

  const maxNumberToPurchase = computed(() => {
    if (hasSecondary.value) {
      if (!is1155.value && floorPrices.value) {
        return floorPrices.value.length;
      }
      return 1;
    }

    if (claimableQuantity.value < Infinity && (hasAllowlist.value || hasWalletLimit.value)) {
      if (claimableQuantity.value === 0) {
        return -1;
      }

      return claimableQuantity.value as number;
    } else {
      return 0;
    }
  });

  const isChainCorrect = computed(() => {
    // Sometimes active network can be undefined for a split second... if undefined, assume correct
    // Otherwise, we get flashing behavior where it says "Please switch to the {NETWORK} to continue".
    if (!activeNetwork.value) {
      return true;
    }

    return networkId.value === activeNetwork.value;
  });

  const hasManifoldFees = computed(() => {
    const traits = EXTENSION_TRAITS[extensionAddress.value];
    return traits && traits.includes('fee');
  });

  const hasProcessingFees = computed(() => {
    return processingFeesInFiat.value.gt(0);
  });

  ///
  // Prices
  ///
  const costInFiat = computed((): BigNumber => {
    return pricingDisplay.value.fixedFiatCost || BigNumber.from(0);
  });

  const priceSubtotal = computed((): BigNumber => {
    if (hasSecondary.value) {
      let t = 0;
      // for 1155
      if (is1155.value && tokenPrices.value) {
        t = tokenPrices.value[floorPriceTokenId.value?.toString() || '0'];

        // for erc721s
      } else if (floorPrices.value && floorPrices.value.length > 0) {
        for (let i = 0; i < tokensToPurchase.value; i++) {
          t = floorPrices.value[i][1] + t;
        }
      }
      return parseEther(t.toFixed(18));
    } else if (isNaN(tokensToPurchase.value) || !tokensToPurchase.value) {
      return BigNumber.from(0);
    }
    return cost.value.mul(tokensToPurchase.value);
  });

  const manifoldFee = computed((): BigNumber => {
    if (isNaN(tokensToPurchase.value) || !tokensToPurchase.value) {
      return BigNumber.from(0);
    }
    let feeToUse = hasAllowlist.value ? FEE_PER_MERKLE_MINT : FEE_PER_MINT;

    if (networkId.value === 137) {
      feeToUse = hasAllowlist.value ? MATIC_FEE_PER_MERKLE_MINT : MATIC_FEE_PER_MINT;
    }

    return feeToUse.mul(tokensToPurchase.value);
  });

  const finalPrice = computed((): BigNumber => {
    if (hasManifoldFees.value) {
      return priceSubtotal.value.add(manifoldFee.value);
    }
    return priceSubtotal.value;
  });

  const costUsdConversion = computed((): number => {
    if (cost.value.eq(0)) {
      return 0;
    }
    if (!ethToUsdRate.value) {
      return 0;
    }

    if (erc20Address.value) {
      return erc20ToUsdRate.value * +formatUnits(cost.value, erc20Decimals.value);
    }
    return ethToUsdRate.value * +formatEther(cost.value);
  });

  const priceSubtotalUsdConversion = computed((): number => {
    if (priceSubtotal.value.eq(0)) {
      return 0;
    }
    if (!ethToUsdRate.value) {
      return 0;
    }

    if (erc20Address.value) {
      return erc20ToUsdRate.value * +formatUnits(priceSubtotal.value, erc20Decimals.value);
    }
    return ethToUsdRate.value * +formatEther(priceSubtotal.value);
  });

  const manifoldFeeUsdConversion = computed((): number => {
    if (manifoldFee.value.eq(0)) {
      return 0;
    }
    if (!ethToUsdRate.value) {
      return 0;
    }
    return ethToUsdRate.value * +formatEther(manifoldFee.value);
  });

  const finalPriceUsdConversion = computed((): number => {
    if (finalPrice.value.eq(0)) {
      return 0;
    }
    if (!ethToUsdRate.value) {
      return 0;
    }
    if (erc20Address.value) {
      return (
        erc20ToUsdRate.value * +formatUnits(finalPrice.value, erc20Decimals.value) +
        manifoldFeeUsdConversion.value
      );
    }
    return ethToUsdRate.value * +formatEther(finalPrice.value);
  });

  const manifoldFeeInFiat = computed((): BigNumber => {
    return BigNumber.from(
      ((manifoldFeeUsdConversion.value || tokensToPurchase.value) * 100).toFixed(0)
    );
  });

  const hasEnoughEth = computed(() => {
    // Don't block the user if we can't fetch their balance
    if (!balance.value) {
      return true;
    }
    if (erc20Address.value) {
      return (
        erc20ApprovedSpend.value.gte(priceSubtotal.value) && balance.value.gte(manifoldFee.value)
      );
    }
    return balance.value.gt(finalPrice.value);
  });

  /** display value for the actual price of the token */
  const priceDisplayText = computed((): string => {
    if (isFreeClaim.value) {
      return 'Free';
    }
    if (displayFiatPrice.value) {
      return `$${formatUSDCentsToDollars(costInFiat.value)}`;
    }
    return `${formatUnits(cost.value, erc20Decimals.value)} ${erc20Symbol.value}`;
  });

  const sigMintErrorMessage = computed(() => {
    if (!sigMintError.value) {
      return '';
    }
    if (sigMintError.value === SigMintError.NO_SECRET) {
      if (signatureSchema.value === SignatureSchema.IYK_TAP) {
        return 'IYK Tap Not Detected';
      }
    }
    if (sigMintError.value === SigMintError.BAD_SECRET) {
      return 'Invalid Tap. Please tap again.';
    }
    if (sigMintError.value === SigMintError.EXPIRED_SIGNATURE) {
      if (signatureSchema.value === SignatureSchema.IYK_TAP) {
        return 'Expired Tap. Please tap again.';
      }
    }
    if (sigMintError.value === SigMintError.SERVER_ERROR) {
      return 'Something went wrong.';
    }
    return 'Something went wrong.';
  });

  ///
  // OTHER
  ///
  watch(mintForWallet, () => {
    if (!mintForWallet.value || !initialized.value) {
      return;
    }
    refreshWeb3State();
  });

  watch(activeNetwork, () => {
    if (!activeNetwork.value || !initialized.value) {
      return;
    }
    refreshWeb3State();
  });

  watch(eligibleVaultWallets, () => {
    // default to the first eligible vault wallet, if the connected wallet is not eligible
    if (
      walletAddress.value &&
      eligibleVaultWallets.value &&
      eligibleVaultWallets.value.length > 0 &&
      !eligibleVaultWallets?.value?.includes(walletAddress.value)
    ) {
      setMintForWallet(eligibleVaultWallets.value[0]);
    }
  });

  ///
  // ACTIONS (dependent on computed)
  ///

  /** adjust mint amount exactly */
  function setTokensToPurchase(amount?: number) {
    if (!amount || amount <= 1) {
      // clip at minimum
      tokensToPurchase.value = 1;
    } else if (amount <= maxNumberToPurchase.value || maxNumberToPurchase.value === 0) {
      tokensToPurchase.value = amount;
    } else if (amount > maxNumberToPurchase.value) {
      // clip at max
      tokensToPurchase.value = maxNumberToPurchase.value;
    }
  }

  /** adjust mint amount by plus one, limiting to max */
  function incrementTokensToPurchase() {
    // can still increase, or if max is 0 (unlimited)
    if (tokensToPurchase.value < maxNumberToPurchase.value || maxNumberToPurchase.value === 0) {
      tokensToPurchase.value += 1;
    }
  }

  /** adjust mint amount by minus one, limiting at 0 */
  function decrementTokensToPurchase() {
    if (tokensToPurchase.value > 1) {
      tokensToPurchase.value -= 1;
    }
  }

  return {
    initialize,
    fetchMerkleInfo,
    resetMerkleData,
    refreshOnChainClaim,
    refreshWeb3State,
    setMintForWallet,
    getFloorPrice,
    initializeFiatPrices,
    fetchingFiatPricesExternally,
    updateFiatPricesFromExternalWidget,
    hasAllowlist,
    walletRestriction,
    isChainCorrect,
    isConnected,
    isUnlimitedSupply,
    isResetingMerkleData,
    canMint,
    hasWalletLimit,
    hasSecondary,
    floorPrices,
    floorPriceTokenId,
    activeNetwork,
    walletAddress,
    ensOrFormattedWalletAddress,
    creatorEnsOrFormattedWalletAddress,
    balance,
    mintForWallet,
    claimableQuantity,
    tokensToPurchase,
    setTokensToPurchase,
    setFloorPrices,
    incrementTokensToPurchase,
    decrementTokensToPurchase,
    priceSubtotal,
    manifoldFee,
    finalPrice,
    costUsdConversion,
    priceSubtotalUsdConversion,
    manifoldFeeUsdConversion,
    finalPriceUsdConversion,
    costInFiat,
    priceSubtotalInFiat,
    manifoldFeeInFiat,
    processingFeesInFiat,
    finalPriceInFiat,
    hasManifoldFees,
    hasProcessingFees,
    loadingFiatPrices,
    hasEnoughEth,
    hasTokensToPurchase,
    hasntMintedAnyTokens,
    isTryingToMintMoreTokensThanPossible,
    maxNumberToPurchase,
    isSoldOut,
    status,
    metadataURL,
    onChainImage,
    initializedInstanceData,
    initialized,
    tokensMintedByWallet,
    merkleInfo,
    id,
    appId,
    name,
    image,
    description,
    contract,
    overrideMintContract,
    slug,
    audienceId,
    merkleTreeId,
    extensionAddress,
    overrideMintExtensionAddress,
    creatorContractAddress,
    claimableMerkleProofs,
    claimableMintIndices,
    claimIndex,
    claimType,
    isPayable,
    pricingDisplay,
    crossmintClientId,
    isCrossmintVerified,

    signatureSchema,
    setSigMintError,
    sigMintError,
    sigMintErrorMessage,
    iykRef,
    iykTimeoutMessage,
    iykTimeoutSignature,
    iykTimeoutNonce,
    setRedemptionCode,
    redemptionCode,
    redemptionCodeValidated,

    animation,
    fallbackProvider,
    networkId,
    creator,
    total,
    totalMax,
    walletMax,
    startDate,
    endDate,
    storageProtocol,
    merkleRoot,
    location,
    tokenId,
    cost,
    displayFiatPrice,
    hideCryptoPrice,
    isFreeClaim,
    priceDisplayText,
    is721,
    is1155,
    isProviderAvailable,
    isLoadingWeb3State,
    isDelegationLoading,
    eligibleVaultWallets,
    delegationError,
    showBadDelegationsWarning,
    checkoutEmojis,
    clientThemedText,
    clientColors,
    clientVideoEmbed,
    clientLogos,
    appBackground,
    mediaBackground,
    clientAnimation,
    erc20Address,
    erc20Symbol,
    erc20Decimals,
    erc20ApprovedSpend,
    erc20Contract,
    ethToUsdRate,
  };
});

export type State = ReturnType<typeof useClaimStore>;
