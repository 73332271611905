export const APPS_BACKEND = 'https://apps.api.manifoldxyz.dev';
export const appsApiBaseUrl =
  process.env.NODE_ENV === 'development' && process.env.VUE_APP_APPS_BACKEND
    ? process.env.VUE_APP_APPS_BACKEND
    : `${APPS_BACKEND}`;

export const SIGNATURE_SERVICE = 'https://signature.api.manifoldxyz.dev';
export const signatureApiBaseUrl =
  process.env.NODE_ENV === 'development' && process.env.VUE_APP_SIGNATURE_SERVICE
    ? process.env.VUE_APP_SIGNATURE_SERVICE
    : `${SIGNATURE_SERVICE}`;

abstract class APIHelpers {
  protected baseUrl: string;

  protected async _getHeaders() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    } as any;

    return headers;
  }

  protected async _fetch(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }

      const requestOptions = {
        method: 'GET',
        headers: await this._getHeaders(),
        ...options,
      };

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }

      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        let result;
        try {
          result = await response.json();
        } catch (e) {
          throw response;
        }

        throw result;
      } else {
        throw error;
      }
    }
  }

  protected async _put(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }

      const requestOptions = {
        method: 'PUT',
        headers: await this._getHeaders(),
        ...options,
      } as any;

      if (options.body) {
        requestOptions.body = JSON.stringify(options.body);
      }

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }
      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        try {
          const result = await response.json();
          throw result;
        } catch (e) {
          throw response;
        }
      } else {
        throw error;
      }
    }
  }

  private async _patch(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }

      const requestOptions = {
        method: 'PATCH',
        headers: await this._getHeaders(),
        ...options,
      } as any;

      if (options.body) {
        requestOptions.body = JSON.stringify(options.body);
      }

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }
      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        try {
          const result = await response.json();
          throw result;
        } catch (e) {
          throw response;
        }
      } else {
        throw error;
      }
    }
  }

  protected async _post(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }
      const requestOptions = {
        method: 'POST',
        headers: await this._getHeaders(),
        ...options,
      } as any;

      if (options.body) {
        requestOptions.body = JSON.stringify(options.body);
      }

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }
      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        try {
          const result = await response.json();
          throw result;
        } catch (e) {
          throw response;
        }
      } else {
        throw error;
      }
    }
  }

  protected async _delete(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }

      const headers = await this._getHeaders();
      delete headers['Content-Type'];

      const requestOptions = {
        method: 'DELETE',
        headers,
        ...options,
      } as any;

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }
      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        try {
          const result = await response.json();
          throw result;
        } catch (e) {
          throw response;
        }
      } else {
        throw error;
      }
    }
  }
}

/**
 * For apps api
 */
class AppsApi extends APIHelpers {
  constructor() {
    super();
    this.baseUrl = appsApiBaseUrl;
  }

  /**
   * Get an instance by id
   */
  async getInstance(instanceId: number) {
    return this._fetch(
      `/public/instance/data?id=${instanceId}&mediaPreview=true&maxMediaWidth=1024`
    );
  }

  /**
   * Get a merkle tree for a wallet
   */
  async getMerkleTree(merkleTreeId: number, walletAddress: string) {
    return this._fetch(`/public/merkleTree/${merkleTreeId}/merkleInfo?address=${walletAddress}`);
  }

  /**
   * Post a transaction hash for an instance
   */
  async postTransaction(appId: number, instanceId: number, hash: string) {
    return this._post('/public/instance/tx', {
      query: {
        appId,
        instanceId,
        hash,
      },
    });
  }

  /**
   * Chceck crossmint status
   */
  async checkCrossmint(crossmintId: string) {
    return this._fetch(`/public/instance/check-crossmint/${crossmintId}`);
  }

  async getMintFeeTotal(
    networkId: number,
    contractAddress: string,
    instanceId: string,
    fromAddress?: string
  ) {
    const query: any = {
      networkId,
      contractAddress,
      instanceId,
    };

    if (fromAddress) {
      query.fromAddress = fromAddress;
    }

    return this._fetch('/public/instance/mintFeeTotal', { query });
  }
}

/**
 * For signature minting api
 */
class SignatureApi extends APIHelpers {
  constructor() {
    super();
    this.baseUrl = signatureApiBaseUrl;
  }

  async iykSign(iykPayload: {
    walletAddress: string;
    extensionAddress: string;
    instanceId: number;
    iykRef?: string;
    iykTimeoutMessage?: string;
    iykTimeoutSignature?: string;
    iykTimeoutNonce?: number;
    mintCount: number;
  }) {
    return this._post('/iyk-sign', {
      body: {
        ...iykPayload,
      },
    });
  }

  async iykValidate(iykPayload: { instanceId: number; iykRef?: string }) {
    return this._post('/iyk-validate', {
      body: {
        ...iykPayload,
      },
    });
  }

  async redemptionSign(redemptionPayload: {
    walletAddress: string;
    extensionAddress: string;
    instanceId: number;
    redemptionCode?: string;
    mintCount: number;
  }) {
    return this._post('/redemption-sign', {
      body: {
        ...redemptionPayload,
      },
    });
  }
}

export const appsApi = new AppsApi();
export const signatureApi = new SignatureApi();
